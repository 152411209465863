import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VsTabmenuIntegrationService } from '@app/widget/vs-tabmenu-integration.service';
import { AppConstants } from '@custom/core/app-constants';
import { uniq, compact, remove, isEqual } from 'lodash';
import { UtilService } from '@app/core/util.service';

@Component({
  selector: 'app-vsmenunewitem',
  templateUrl: './vsmenunewitem.component.html',
  styleUrls: [ './vsmenunewitem.component.scss' ]
})
export class VsmenunewitemComponent implements OnInit {

  @Input() menu: any;
  @Input() menuLevel: number;
  @Input() menuType: string;

  @ViewChild('menuRouter') menuRouter: ElementRef;
  @Output() menuActive = new EventEmitter();

  isMobile: boolean;

  constructor(private activatedRoute: ActivatedRoute, private tabMenuIntegration: VsTabmenuIntegrationService, private route: Router, private utils: UtilService) { }

  getIconClass(menu) {
    const fontBaseClass = menu.useIconFrom ? this.utils.getFontBaseClass(menu.useIconFrom) : 'fa';
    const icon = menu.icon ? menu.icon : 'fa-database';
    return (menu.useIconFrom === 'AppDirectory') ? `${fontBaseClass}` : `${fontBaseClass} ${icon}`;
  }

  getFontBaseClass(iconfrom) {
    switch (iconfrom) {
      case 'Font Awesome': return 'fa';
      case 'Material': return 'material-icons';
      default: return 'fa';
    }
  }
  /**
    * Get image url and set it as background image
    */
  getIconImage(menu) {
    const iconType: string = menu.useIconFrom ? menu.useIconFrom.replace(/ /g, '') : '';
    return iconType === 'AppDirectory' ? AppConstants.appDirectoryRoot + menu.icon : '';
  }


  getIconLabelForMaterialIcons(menu) {
    const iconType: string = menu.useIconFrom ? menu.useIconFrom.replace(/ /g, '') : '';
    return iconType === 'Material' ? menu.icon : '';
  }

  checkIfActive(menuObj: any): boolean {

    const menu = menuObj.path;
    if (!menu) {
      return false;
    }
    const paths = menu.split('/');
    let isActive = false;
    const href = window.location.href;
    for (let i = 0;i < paths.length;i++) {
      const path = paths[ i ];
      if (path && href.indexOf(path) > -1) {
        this.tabMenuIntegration.changeTab(menuObj);
        menuObj.active = true;
        isActive = true;
        break;
      }
    }

    return isActive;
  }

  checkForActive(menuObj: any): boolean {
    const path = menuObj.path;
    const url = window.location.href;
    if (url.indexOf(path) > -1) {
      return true;
    }
    return false;
  }

  handleMenuClick(e: Event, menu: any) {
    if (menu.subTabs) {
      this.route.navigate([ menu.path ]);
      this.tabMenuIntegration.changeTab(menu);
      this.tabMenuIntegration.changeMenuOpenState(false);
    } else {
      this.tabMenuIntegration.changeTab([]);
    }

    if (menu.subMenus && menu.subMenus.length) {
      this.tabMenuIntegration.changeMenuOpenState(true);
    } else if (menu.path && menu.path.length) {
      this.tabMenuIntegration.changeMenuOpenState(false);
      this.route.navigate([ menu.path ]);
    } else {
      this.tabMenuIntegration.changeMenuOpenState(false);
    }
    e.stopPropagation();
  }

  isDetailPath(pArr, hashArr, detailPagePath) {
    return pArr.includes(detailPagePath);
  }

  isActive(menu) {
    const urlHash = window.location.hash;
    let detailPagePath = '';
    let isDetail = false;
    if (urlHash.indexOf('fp=') > -1) {
      detailPagePath = urlHash.substring(urlHash.lastIndexOf('fp=') + 3);
      isDetail = detailPagePath.length > 0;
    }
    let path = urlHash;
    if (urlHash.indexOf(';') > -1) {
      path = urlHash.substring(0, urlHash.indexOf(';') + 1);
    }
    const hashArr = path.split('/').splice(1);
    if (menu.pathsInvolved && menu.pathsInvolved.length) {
      const matched = menu.pathsInvolved.filter((p) => {
        const pArr = compact(p.split('/'));
        return isEqual(pArr, hashArr) || (isDetail && this.isDetailPath(pArr, hashArr, detailPagePath));
      });
      if (matched.length) {
        this.menuActive.emit(menu);
        return true;
      }
    }
    return false;
  }


  ngOnInit() {
    this.isMobile = AppConstants.isMobile;
  }

}
