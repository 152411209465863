import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FirebaseAuthenticationService {

  constructor(private af: AngularFireAuth) { }
  public signinToken = (token: any) => {
    const firebaseSignon = new Observable((observer) => {
      this.af.auth.signInWithCustomToken(token).then((data: any) => {
        observer.next(data);
      },
        (error: any) => {
          observer.error(error);
        });
    });
    return firebaseSignon;
  }
}


