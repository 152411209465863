import { Component, OnInit, forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageCaptureService } from './image-capture.service';

@Component({
  selector: 'app-image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: [ './image-capture.component.scss' ]
})
export class ImageCaptureComponent implements OnInit {
  constructor(private imageCaptureService: ImageCaptureService) { }

  ngOnInit() { }

  onFileSelected(event) {
    this.imageCaptureService.emit(event);
  }

}
