import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-customtext',
  templateUrl: './customtext.component.html',
  styleUrls: ['./customtext.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomtextComponent),
      multi: true
    }
  ]
})
export class CustomtextComponent implements OnInit, ControlValueAccessor {

  @Input() element: any;

  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  public text: string | any;

  constructor() { }

  get model() {
    return this._model;
  }

  writeValue(value: any): void {
    this.setValueToText(value);
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }

  setValueToText(value) {
    if (this.element.multiple && Array.isArray(value)) {
      this.text = value.join(',');
    } else {
      this.text = value;
    }
  }

  onBlur = (event) => {
    let value = '';
    if (this.element.multiple) {
      value = this.text.split(',').filter(Boolean);
    } else {
      value = this.text;
    }
    this._model = value;
    this.onChange(this._model);
  }

  ngOnInit() {
  }

}
