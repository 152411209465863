import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VsScannerService {
  subject = new Subject<string>();

  constructor() { }

  public emit(value: string) {
    this.subject.next(value);
  }
}
