import { Component, OnInit, Input, AfterViewInit, ElementRef, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { AppGlobalService } from '@app/app-global.service';
import { UtilService } from '@app/core/util.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { BaseService } from '@app/core/base.service';

declare const $: any;
declare const _: any;

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges, AfterViewInit {
  public static readonly colors = ['#e9578c', '#b675f3', '#12a5b8', '#51b255', '#dfaa0a',
    '#e36943', '#34A853', '#009688', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
    '#2196f3', '#009688', '#4caf50', '#ffeb3b', '#ff9800', '#ff5722'];
  @Input() commentConfig: any;
  private userColor: {};
  private comData: any;
  private unsubscribe: any[];
  private comTxt: any;
  private comments: any;
  private userInfo: any;
  deleteMaskOpen: any;
  commentPopupOpen: boolean;
  avblCells: string[];
  bComments: any;
  constructor(
    private ags: AppGlobalService,
    private util: UtilService,
    private elementRef: ElementRef,
    private firestore: AngularFirestore,
    private bsService: BaseService
  ) { }
  public getNamefromEmail = (email: string) => {
    return this.util.getNameFromEmail(email);
  }
  public getdayDate(ts) {
    const today = new Date().setHours(0, 0, 0, 0);
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0);
    const thatDay = new Date(ts).setHours(0, 0, 0, 0);
    if (today === thatDay) {
      return 'Today';
    } else if (yesterday === thatDay) {
      return 'Yesterday';
    } else {
      return this.util.formatDateTime(ts, 'dd-MMM-yyyy');
    }
  }
  public setRandomColors(comments) {
    this.userColor = {};
    if (comments && Object.keys(comments).length > 0) {
      _.map(comments, (com) => {
        if (!this.userColor[com.who])
          this.userColor[com.who] = CommentsComponent.colors[Object.keys(this.userColor).length];
      });
    }
  }
  public showmorelessinitial() {
    setTimeout(() => {
      const commentsbody = $(this.elementRef.nativeElement).find('.static-comment');
      commentsbody.each((idx, element) => {
        if (element.clientHeight < element.scrollHeight) {
          $(element).parents('.comments-replyview-static').find('.comments-show-more').show();
        } else {
          console.log('No');
        }
      });
    }, 100);
  }
  public loadCommentPopup() {
    this.comTxt = '';
    const split = this.commentConfig.cellSelected.split('_$_');
    const curTgt = $('td[row=' + split[0] + '][col=' + split[1] + ']');
    const appCom = $('app-comments');
    const jxlCont = $('.jexcel_content');
    const comWrap = $('.comments-wrapper');
    appCom.find('.comments-inner-wrapper').css({
      'max-height': jxlCont.height() - $('.comments-input-pane').height() - 100 + 'px'
    });
    let left = curTgt.offset().left;
    if (comWrap.width() + left > jxlCont.width()) {
      left = left - comWrap.outerWidth() - curTgt.outerWidth();
    }
    let top = curTgt.offset().top - 130;
    if (comWrap.height() + top > jxlCont.height()) {
      top = jxlCont.outerHeight() - comWrap.outerHeight();
    }
    appCom.css({
      top,
      left
    });
    // $('.comments-wrapper').find('textarea').focus();
  }
  /**
   * Intialize loading of comments based on the reference input from controller
   */
  public setCommentListners() {
    if (!(this.commentConfig && this.commentConfig.entityId)) return;
    this.commentConfig.avblCells = [];
    const doc = this.firestore.collection(this.commentConfig.comments_collection || 'SheetComments')
      .doc(this.commentConfig.entityName + '_$_' + this.commentConfig.entityId).valueChanges();
    this.unsubscribe.push(doc.subscribe((data) => {
      this.normalizeComments(data);
      if (this.commentConfig.popupOpen) {
        this.loadcomments();
      }
    }, (error) => {
      console.log(error);
    }));
  }
  public normalizeComments(data) {
    if (data && data.comments) {
      this.commentConfig.avblCells = Object.keys(data.comments);
    }
    setTimeout(() => {
      this.commentConfig.updateCellComIcon(this.commentConfig.avblCells);
    }, 500);
    this.bComments = JSON.parse(JSON.stringify(data));
    this.comments = JSON.parse(JSON.stringify(data));
  }
  public loadcomments() {
    this.comData = {};
    if (Object.keys(this.comments).length > 0) {
      this.comData = { ...this.comments };
      if (this.comData.comments && this.comData.comments[this.commentConfig.cellSelected]) {
        this.comData.comments = this.comData.comments[this.commentConfig.cellSelected];
      } else {
        this.comData = {};
      }
    }
    this.userInfo = { email: 'jjithin@vanenburgsoftware.com' };
    if (Object.keys(this.comData).length === 0) {
      this.comData = {
        new: true,
        comments: {
          new: {
            who: this.userInfo.email || 'jjithin@vanenburgsoftware.com',
          }
        }
      };
    }
    this.setRandomColors(this.comData.comments);
    this.showmorelessinitial();
    this.commentConfig.popupOpen = true;
    this.loadCommentPopup();
  }
  public mouseOverCommentPopup() {
    this.commentConfig.popupFocused = true;
  }
  public mouseOutCommentPopup() {
    if (!$(this.elementRef.nativeElement).find('textarea').is('focus')) {
      this.commentConfig.popupFocused = false;
    }
  }
  ngOnInit() {
    this.userInfo = this.ags.get('currentUser') || { email: 'jjithin@vanenburgsoftware.com' };
    this.unsubscribe = [];
    this.comData = {};
    this.setCommentListners();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!(this.commentConfig && this.commentConfig.cellSelected))
      return;
    this.loadcomments();
  }
  public showMoreLess(value, ev) {
    const target = $(ev.target);
    target.hide();
    if (value) {
      target.parents('.comments-replyview-static').find('.comments-show-more').show();
      target.parent('.comments-replyview-static').find('.static-comment').css('max-height', '64px');
    } else {
      target.parents('.comments-replyview-static').find('.comments-show-less').show();
      target.parents('.comments-replyview-static').find('.static-comment').css('max-height', 'initial');
    }
  }
  public adjsutTextareaHeight(target) {
    target.style.height = '34px';
    if (target.scrollHeight > target.offsetHeight) {
      target.style.height = target.scrollHeight + 2 + 'px';
    }
  }
  public onCommentTextarea(ev) {
    const curTargt = ev.currentTarget;
    this.adjsutTextareaHeight(curTargt);
  }
  public editComment(ev) {
    $(ev.target).parents('.comments-section').find('.static-comment,.comments-show-more,.comments-show-less').hide();
    $(ev.target).parents('.comments-section').find('.textarea-comment').show().find('.comments-input-textarea').focus();
    this.adjsutTextareaHeight($(ev.target).parents('.comments-section').find('.textarea-comment').find('.comments-input-textarea')[0]);
  }
  public cancelChanges(ev, type, comment) {
    if (type === 'comment' || type === 'reply') {
      this.comTxt = '';
    } else if (type === 'update') {
      comment.value.com = this.bComments.comments[this.commentConfig.cellSelected][comment.key].com;
    }
  }
  public canceldConfirmationBox(comment, index) {
    if (index === 0) {
      delete this.comData.mask;
    } else {
      delete comment.value.mask;
    }
  }
  public deleteConfirmationBox(ev, comment, index) {
    if (index === 0) {
      if (this.deleteMaskOpen) {
        this.deleteMaskOpen.value.mask = false;
        this.deleteMaskOpen = null;
      }
      this.comData.mask = true;
    } else {
      // const tar = $(ev.target);
      const pTar = $(ev.target).parents('.comments-section');
      comment.value.mask = true;
      setTimeout(() => {
        pTar.find('.comment-mask').css({
          height: pTar.outerHeight(),
          width: pTar.outerWidth()
        });
      });
      this.deleteMaskOpen = comment;
    }
  }
  public deleteComment(ev, index, comment) {
    const obj = {
      entityName: this.commentConfig.entityName,
      entityId: this.commentConfig.entityId,
      sid: this.comments.sid,
      comments: {}
    };
    const url = 'delete';
    if (index === 0) {
      obj.comments[this.commentConfig.cellSelected] = null;
    } else {
      obj.comments[this.commentConfig.cellSelected] = {};
      obj.comments[this.commentConfig.cellSelected][comment.key] = null;
    }
    const options = {
      url: this.commentConfig.crud.api + '/' + url,
      method: 'PUT',
      showloading: true
    };
    this.bsService.put(options, obj).subscribe(() => {
      this.comTxt = '';
      // this.hidepopup();
    });
  }
  public saveChanges(ev, type, comment) {
    if ((type === 'comment' || type === 'reply') && comment.length > 0) {
      const options = {
        url: this.commentConfig.crud.api + '/create',
        method: 'POST',
        showloading: true
      };
      const obj = {
        entityName: this.commentConfig.entityName,
        entityId: this.commentConfig.entityId,
        comments: {}
      };
      obj.comments[this.commentConfig.cellSelected] = {
        com: comment
      };
      this.bsService.post(options, obj).subscribe(() => {
        this.comTxt = '';
        // this.hidepopup();
      });
    } else if (type === 'update') {
      const options = {
        url: this.commentConfig.crud.api + '/update',
        method: 'PUT',
        showloading: true
      };
      const obj = {
        entityName: this.commentConfig.entityName,
        entityId: this.commentConfig.entityId,
        comments: {}
      };
      obj.comments[this.commentConfig.cellSelected] = {};
      obj.comments[this.commentConfig.cellSelected][comment.key] = {
        com: comment.value.com
      };
      this.bsService.put(options, obj).subscribe(() => {
        this.comTxt = '';
        // this.hidepopup();
      });
    }
  }
  public hidepopup() {
    const commentTgt = $('.comments-wrapper');
    this.commentConfig.popupOpen = false;
    commentTgt.parents('app-comments').css({ left: '-1000px', top: '-1000px' });
    commentTgt.find('textarea').css({ height: 'auto' });
  }
  @HostListener('document:mousedown', ['$event'])
  clickout(event) {
    const commentTgt = $('.comments-wrapper');
    const curTar = $(event.target);
    if (this.commentConfig.popupOpen && curTar.parents('.comments-wrapper').length === 0) {
      this.hidepopup();
    }
  }
  ngAfterViewInit() {
  }
}

