import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private viewersSource = new BehaviorSubject('default message');
  currentCellClick = this.viewersSource.asObservable();

  constructor() { }

  changeCell(cellAttr: string) {
    this.viewersSource.next(cellAttr);
  }

}