import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCanLoadGuard } from './auth/_guards';
import { HomeComponent } from './home/home.component';
import {extendAppRoutingModuleRoutes } from '@custom/core/app-routing-module-handler';

let routes: Routes = [  
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },  
  {
    path: 'home',
    component: HomeComponent,
    canLoad: [ AuthCanLoadGuard ],
    children: [
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full'
      }, {
        path: 'landing',
        loadChildren: './landing-page/landing-page.module#LandingPageModule'
      },
      {
		path: 'devcenter',
		canLoad: [ AuthCanLoadGuard ],
		data: {
			expectedRoles: [ 999 ]
		},
		loadChildren: './dev-center/dev-center.module#DevCenterModule'
	  },
	  	  {
	    path: 'schoolboard',
	    /** loadChildren: () => import('./schoolboard/schoolboard.module').then(mod => mod.SchoolboardModule) */
	    loadChildren: './schoolboard/schoolboard.module#SchoolboardModule'
	  },
	  {
	    path: 'sector',
	    /** loadChildren: () => import('./sector/sector.module').then(mod => mod.SectorModule) */
	    loadChildren: './sector/sector.module#SectorModule'
	  },
	  {
	    path: 'team',
	    /** loadChildren: () => import('./team/team.module').then(mod => mod.TeamModule) */
	    loadChildren: './team/team.module#TeamModule'
	  },
	  {
	    path: 'location',
	    /** loadChildren: () => import('./location/location.module').then(mod => mod.LocationModule) */
	    loadChildren: './location/location.module#LocationModule'
	  },
	  {
	    path: 'appuserprivileges',
	    /** loadChildren: () => import('./app-user-privileges/app-user-privileges.module').then(mod => mod.AppUserPrivilegesModule) */
	    loadChildren: './app-user-privileges/app-user-privileges.module#AppUserPrivilegesModule'
	  },
	 ]
  },
  {
	path: '**',
	redirectTo: 'login',
  }
];

const config = {
  useHash: true
};

routes = extendAppRoutingModuleRoutes(routes);

@NgModule({
  imports: [ RouterModule.forRoot(routes, config) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }