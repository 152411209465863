import { AppGlobalService } from "./../app-global.service";
import { Component, Injector, OnInit, OnDestroy } from "@angular/core";
import { TranslateService, TranslatePipe } from "@ngx-translate/core";
import { Subscription, combineLatest, Observable } from "rxjs";

import { VsMenuService } from "./../widget/vs-menu/vs-menu.service";
import { AuthenticationService } from "./../auth/authentication.service";
import { User } from "./../auth/user.model";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  ActivatedRoute,
} from "@angular/router";
import { AppConstants } from "@custom/core/app-constants";
import { UtilService } from "./../core/util.service";
import { FirebaseAuthenticationService } from "./../auth/firebase-authentication.service";
import { environment } from "@env/environment";
// tslint:disable-next-line:ban-types
declare let ga: Function;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit, OnDestroy {
  title = "Angular Libraries";

  public topbarConfig: any;

  isExpandedMenu: boolean;
  /** Check menu is sticky or not */
  isStickyMenu: boolean;

  menuStateChangeSub: Subscription;
  userSubscription: Subscription;
  isTab: boolean;
  isMobile: boolean;
  tabs: any = [];
  isShowTabList = false;
  isCollapsedMenu = false;
  authenticateSub: Subscription;
  tokenSubstription: Subscription;

  constructor(
    private menuService: VsMenuService,
    private translate: TranslateService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private appGlobalService: AppGlobalService,
    private utilService: UtilService,
    private fauth: FirebaseAuthenticationService
  ) {
    /* fallback language when a translation isn't found in the current language */
    translate.setDefaultLang("en");
    translate.use("en");

    /**
     * Expand and collpase page container area based on the menustate change value
     */
    this.menuStateChangeSub = this.menuService.menuStateChange.subscribe(
      (data) => {
        this.isExpandedMenu = data === "open";
      }
    );
  }

  public authenticate(): Observable<any> {
    const promise = [];
    promise.push(this.auth.authenticate());
    // if (!environment.prototype)
    //   promise.push(this.auth.getfsToken());
    return combineLatest(promise);
  }

  public setUserData(data) {
    if (data) {
      this.auth.setCurrentUser(data);
      let userName = "";
      userName = data.firstName ? userName + data.firstName : userName;
      userName = data.lastName ? userName + " " + data.lastName : userName;

      if (!userName) {
        userName = "Admin";
      }

      this.topbarConfig.data = {};
      this.topbarConfig.data.userName = userName;

      this.topbarConfig.menuConfig = data.menu;
      this.menuService.emitMenuDta(data.menu);
      this.isStickyMenu = this.topbarConfig.menuConfig.sticky;
    }
  }

  ngOnInit() {
    this.utilService.setTitle();
    this.utilService.setFavIcon();
    this.loadTopbarConfig();
    this.isMobile = AppConstants.isMobile;
    this.authenticateSub = this.authenticate().subscribe((result) => {
      const userdata = result[0];
      if (!environment.prototype && result[1]) {
        const tokenInfo = result[1];
        // this.fauth.signinToken(tokenInfo).subscribe(() => {
        //   this.setUserData(userdata);
        // });
        this.setUserData(userdata);
      } else {
        this.setUserData(userdata);
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // TODO: add file for hook handler and show spinner
      }

      if (event instanceof NavigationEnd) {
        // TODO: add file for hook handler and hide spinner
        this.isTab = false;
        if (
          event.urlAfterRedirects.indexOf("/tab/") > -1 ||
          event.urlAfterRedirects === "/tab"
        ) {
          this.isTab = true;
        }

        ga("set", "page", event.urlAfterRedirects);
        ga("send", "pageview");

        this.handleNavigationEnd(event);
      }

      if (event instanceof NavigationError) {
        // TODO: add file for hook handler and show notification
        console.log(event.error);
      }
    });
  }

  handleNavigationEnd(event) {
    let route = this.route.firstChild;
    let child = route;

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
        route = child;
      } else {
        child = null;
      }
    }

    route.data.subscribe((data) => {
      this.resetAllTabs();
      console.log(route);
      if (
        !AppConstants.isFromTab &&
        !this.checkIfTabExists(data, event).length
      ) {
        this.tabs.push({
          label: data.label || event.urlAfterRedirects,
          url: window.location.hash.substr(1), // event.urlAfterRedirects,
          component: route.component,
          fromTab: true,
          active: true,
          params: route.snapshot.params || "",
        });
      } else {
        this.loadTabFromList(data.label || event.urlAfterRedirects);
      }
      AppConstants.isFromTab = false;
    });
  }

  checkIfTabExists(data, event) {
    return this.tabs.filter((tab) => {
      return tab.label === data.label || tab.label === event.urlAfterRedirects;
    });
  }

  resetAllTabs() {
    this.tabs.map((tab) => {
      tab.active = false;
      return tab;
    });
  }

  loadTabFromList(label) {
    this.tabs.map((tab) => {
      if (tab.label === label) {
        tab.active = true;
      }
      return tab;
    });
  }

  loadThisTab(tab) {
    AppConstants.isFromTab = true;
    this.resetAllTabs();
    tab.active = true;
    if (tab.url.indexOf(";")) {
      tab.url = tab.url.split(";")[0];
    }
    this.router.navigate([tab.url, tab.params]);
    this.isShowTabList = false;
  }

  removeThisTab(tab) {
    this.tabs = this.tabs.filter((item) => item.label !== tab.label);
  }

  toggleLeftMenu() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
  }

  move(direction) {
    const elem = $("#openedTabsHorzWrap");
    const scrollLeft = elem.scrollLeft();
    if (direction === "left") {
      //elem.scrollLeft(scrollLeft - 100);
      elem.animate({ scrollLeft: "-=200" }, 500);
    } else if (direction === "right") {
      //elem.scrollLeft(scrollLeft + 100);
      elem.animate({ scrollLeft: "+=200" }, 500);
    }
  }

  private loadTopbarConfig(): void {
    this.topbarConfig = {
      langConfig: [
        {
          label: "EN",
          flagIcon: "en",
          value: "en",
        },
        {
          label: "NL",
          flagIcon: "nl",
          value: "nl",
        },
      ],
    };
  }

  ngOnDestroy() {
    this.menuStateChangeSub.unsubscribe();
    this.authenticateSub.unsubscribe();
  }
}
