import { VsScannerService } from './../vs-scanner/vs-scanner.service';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { VsSearchService } from './vs-search.service';
import { VsFormComponent } from '../vs-form/vs-form.component';
import { Router } from '@angular/router';
import { AppGlobalService } from '@app/app-global.service';
import moment from 'moment';

@Component({
  selector: 'app-vs-search',
  templateUrl: './vs-search.component.html',
  styleUrls: ['./vs-search.component.scss']
})
export class VsSearchComponent implements OnInit {

  @Input() searchconfig;
  public showAdvancedSearch: boolean;
  public searchFormConfig: any;
  public data: any = {};
  public search: boolean;
  public currentList: any = {};

  @ViewChild(VsFormComponent) child;
  @ViewChild('globalSearch') gsearch;

  constructor(
    private searchService: VsSearchService,
    private scannerService: VsScannerService,
    private router: Router,
    private appGlobalService: AppGlobalService
  ) { }

  getSearchFormConfig() {
    const searchConfig = {
      initCallback: 'IncidentsDetailFormInitComplete',
      collapsible: false,
      submit: 'saveBasicDetails',
      class: 'no-margin form-elements-group',
      securityEvaluation: false,
      staticSecurityJson: {},
      disabledForm: false,
      columns: 100,
      groups: [
        {
          groupHeaderClass: 'hidden hidden',
          groupContentClass: 'paddingZero',
          collapsible: 'false',
          columns: this.searchconfig.columns ? this.searchconfig.columns : 50,
          label: '',
          disableRights: false,
          columnsWidth: 12,
          items: this.searchconfig.advSearch
        }
      ]
    };
    return searchConfig;
  }

  toggleAdvancedSearch() {
    if (this.appGlobalService.get(this.currentList)) {
      this.data = JSON.parse(this.appGlobalService.get(this.currentList));
    }
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  searchGrid(data?) {
    const searchData = {};
    const srchValues = data ? data : this.child.form.value;
    for (const key in srchValues) {
      if (srchValues.hasOwnProperty(key) && srchValues[key].toString().length) {
        if (key.toLowerCase().indexOf('date') > -1) {
          searchData[key] = new Date(moment.utc(srchValues[key]).format('MM-DD-YYYY')).getTime();
        } else {
          searchData[key] = srchValues[key];
        }
      }
    }
    this.searchService.changeAdvSearchObj(searchData);
    this.search = true;
    this.data = searchData;
    this.appGlobalService.write(this.currentList, JSON.stringify(this.data));
    this.toggleAdvancedSearch();
  }

  cancelSearch() {
    this.toggleAdvancedSearch();
  }

  clearSearch() {
    if (this.child) {
      this.child.form.patchValue({});
    }
    this.appGlobalService.write(this.currentList, JSON.stringify({}));
    this.search = false;
    this.data = {};
  }

  clearAllFilters() {
    this.gsearch.nativeElement.value = '';
    this.searchService.changeGlobalSearchKey('');
    this.clearSearch();
    this.showAdvancedSearch = false;
    this.search = false;
  }

  fireInitialSearch() {
    if (this.appGlobalService.get(this.currentList)) {
      this.data = JSON.parse(this.appGlobalService.get(this.currentList));
      this.searchGrid(this.data);
    }
  }

  ngOnInit() {
    this.currentList = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);
    this.fireInitialSearch();
    this.showAdvancedSearch = false;
    if (this.searchconfig && this.searchconfig.advSearch) {
      this.searchFormConfig = this.getSearchFormConfig();
    }

    if (this.searchconfig.fromScanner) {
      this.scannerService.subject.subscribe(val => {
        this.gsearch.nativeElement.value = val;
      });
    }
  }

  public onKeydown(event: any) {
    if (event.which === 13 || event.keyCode === 13) {
      this.search = true;
      this.searchService.changeGlobalSearchKey(event.target.value);
    }
  }

}
