/**
 * Spreadsheet Component
 * @example
 * <app-vs-spreadsheet></app-vs-spreadsheet>
 * @attributes,
 */
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../core/util.service';
import { VsSpreadsheetService } from './vs-spreadsheet.service';
import { DataService } from '../data.service';
import { FsBaseService } from '@app/core/fs-base.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { CommentsComponent } from '../comments/comments.component';
import { AppGlobalService } from '@app/app-global.service';
import { appendNgContent } from '@angular/core/src/view/ng_content';

declare const jexcel: any;
declare const $: any;

@Component({
  selector: 'app-vs-spreadsheet',
  templateUrl: './vs-spreadsheet.component.html',
  styleUrls: ['./vs-spreadsheet.component.scss']
})
export class VsSpreadsheetComponent implements OnInit {
  private sheet: any;
  private commentConfig: any;
  constructor(
    private ts: TranslateService,
    private util: UtilService,
    private spreadsheetService: VsSpreadsheetService,
    private data: DataService,
    private fsService: FsBaseService,
    private notification: NotificationService,
    private ags: AppGlobalService,
  ) { }
  @Input() sheetConfig: any;
  public cellSelected: any;
  private customServices = {
    componentServices: {
      translationService: (title: any) => {
        return title ? this.ts.instant(title) : title;
      },
      loadSpreadsheetData: (...args) => {
        if (args[0] === 'sort') {
          const split = args[1].split('_$_');
          this.sheetConfig.listner.orderby = [{ field: split[0], dir: split[1] }];
          delete this.sheetConfig.listner.startafter;
        }
        this.loadSpreadsheetData(args);
      },
      showNotification: (msg: any, type) => {
        this.notification[type](msg);
      },
      openComments: (e, x, y) => {
        const attr = $(this.sheet.records[y][x]);
        setTimeout(() => {
          this.commentConfig = { ...this.sheetConfig.commentConfig };
          this.commentConfig.cellSelected = attr.attr('row') + '_$_' + attr.attr('col');
        }, 100);
        if (e.type === 'mouseover') {
          if (attr.attr('data-editor-title')) {
            const span = $('<span>').html(attr.attr('data-editor-title'));
            span.addClass('realtime-user-tooltip');
            $(this.sheet.el).append(span);
            span.css({
              top: (attr.offset().top - 14) + 'px',
              left: (attr.offset().left + attr.outerWidth()) + 'px',
              'margin-left': -1 * span.outerWidth(),
              background: attr.css('border-color')
            });
          }
        }
      },
      closeComments: (x, y) => {
        setTimeout(() => {
          if (!this.commentConfig.popupFocused)
            this.commentConfig.popupOpen = false;
        }, 50);
        $(this.sheet.el).find('.realtime-user-tooltip').remove();
      },
      setCmntIcon: (td, cell) => {
        if (this.commentConfig.avblCells && this.commentConfig.avblCells.indexOf(cell) >= 0) {
          this.addCommentIcon(cell);
        }
      },
      updateAllCellComIcon: (cells) => {
        $(this.sheet.el).find('td').removeClass('cmticon');
        $.map(cells, (cell) => {
          this.addCommentIcon(cell);
        });
      }
    }
  };
  public addCommentIcon(cell) {
    const split = cell.split('_$_');
    $(this.sheet.el).find('td[row=' + split[0] + '][col=' + split[1] + ']').addClass('cmticon');
  }
  ngOnInit(): void {
    this.loadSpreadsheetConfig();
    $('.global-search input').keydown((e) => {
      this.sheet.search(e.target.value);
    });
    $('.spreadsheet-column-filter').mousedown((e) => {
      this.sheet.options.filters = this.sheetConfig.filters = !this.sheet.options.filters;
      this.sheet.setColumnFilters();
    });
    this.commentConfig = this.sheetConfig.commentConfig;
    this.commentConfig.updateCellComIcon = this.customServices.componentServices.updateAllCellComIcon;
  }
  private loadSpreadsheetData(args) {
    if (this.sheetConfig && this.sheetConfig.listner) {
      const service = this.fsService.fireService(this.sheetConfig.listner);
      if (this.sheetConfig.subscribe)
        this.sheetConfig.subscribe.unsubscribe();
      this.sheetConfig.subscribe = service.subscribe(response => {
        if (this.sheet === undefined || args[0] === 'scroll' || args[0] === 'sort' || args[0] === 'filter') {
          this.sheetConfig.listner.startafter = response[response.length - 1].payload.doc;
          let data = $.map(response, (doc: any) => {
            return doc.payload.doc.data();
          });
          data = this.sheetConfig.onAjaxResponse(data, response[0].payload).data;
          if (this.sheet === undefined) {
            this.sheetConfig.data = data;
            this.sheet = jexcel($('#spreadsheet')[0], this.sheetConfig);
          } else if (args[0] === 'scroll') {
            this.sheet.setscrollData(data);
          } else if (args[0] === 'sort' || args[0] === 'filter') {
            this.sheet.setnewData(data, args[0]);
          }
          this.sheet.options.availScroll = false;
          if (this.sheet.options.infiniteScroll === true && response.length === this.sheetConfig.listner.limit) {
            this.sheet.options.availScroll = true;
          }
        } else {
          $.map(response, (doc: any) => {
            const result = this.sheetConfig.onAjaxResponse(doc.payload.doc.data(), doc.payload);
            let row = result.payload.newIndex;
            const elem = $(this.sheet.el).find('tr[row = ' + result.data.sid + ']');
            if (elem.length > 0) {
              row = elem.attr('data-y');
            }
            if (doc.payload.type === 'modified')
              this.sheet.setRowData(row, result.data, true);
            else if (doc.payload.type === 'added') {
              let insertBefore = true;
              if (row >= this.sheet.options.bData.length) {
                insertBefore = false;
              }
              this.sheet.insertRow([result.data], row, insertBefore, true);
              this.sheet.setRowData(row, result.data, true);
            } else if (doc.payload.type === 'removed') {
              this.sheet.deleteRow(Number(row), 1, true);
            }
          });
        }
        args = [];
      });
    } else {
      this.sheet = jexcel($('#spreadsheet')[0], this.sheetConfig);
    }
  }
  private onfocus(el) {
    const rcCell = el.getAttribute('row') + '_$_' + el.getAttribute('col');
    this.data.changeCell(rcCell);
    if (this.sheetConfig.jexcelEvents.onfocus)
      this.sheetConfig.jexcelEvents.onfocus(el);
  }
  private onafterchanges(...args) {
    if (this.sheetConfig.jexcelEvents.onafterchanges)
      this.sheetConfig.jexcelEvents.onafterchanges(...args);
  }
  private ondeleterow(el, rowNumber, numOfRows, rowRecords) {
    if (this.sheetConfig.jexcelEvents.ondeleterow)
      this.sheetConfig.jexcelEvents.ondeleterow(el, rowNumber, numOfRows, rowRecords);
  }
  private iterateEvents(): void {
    const jexcelEvts = ['onload', 'onbeforechange', 'onchange', 'onafterchanges', 'onpaste',
      'onbeforepaste', 'oninsertrow', 'onbeforeinsertrow', 'ondeleterow', 'onbeforedeleterow',
      'oninsertcolumn', 'onbeforeinsertcolumn', 'ondeletecolumn', 'onbeforedeletecolumn',
      'onmoverow', 'onmovecolumn', 'onresizerow', 'onresizecolumn', 'onselection',
      'onsort', 'onfocus', 'onblur', 'onmerge', 'onchangeheader', 'onundo', 'onredo',
      'oneditionstart', 'oneditionend', 'onchangestyle', 'onchangemeta', 'onchangepage'];
    $.map(jexcelEvts, (key) => {
      if (key && typeof this[key] === 'function') {
        const self = this;
        this.sheetConfig[key] = (...args) => {
          self[key](...args);
        };
      } else if (this.sheetConfig.jexcelEvents && this.sheetConfig.jexcelEvents[key]) {
        this.sheetConfig[key] = this.sheetConfig.jexcelEvents[key];
      }
    });
  }
  private loadSpreadsheetConfig(): void {
    if (this.sheetConfig.infiniteScroll) {
      this.sheetConfig.params = {
        start: 0,
        length: this.sheetConfig.defaultDataLoad || 50,
      };
    }
    this.iterateColumns();
    this.iterateEvents();
    this.sheetConfig = Object.assign(this.sheetConfig, this.customServices);
    this.customServices.componentServices.loadSpreadsheetData();
  }
  private iterateColumns(): void {
    const params = {
      draw: 1,
      order: [],
      start: 0,
      length: this.sheetConfig.defaultDataLoad || 50,
      search: {
        value: {},
        regex: false
      },
      pageToken: null,
      jobId: null,
      columns: []
    };
    $.map(this.sheetConfig.columns, (column: any) => {
      if (column.type === 'numeric') {
        if (column.currencyCode && (!column.options || !column.options.format)) {
          column.options = $.extend({}, column.options, {
            format: this.util.getCurrencySymbol(column.currencyCode) + '_._2_ '
          });
        }
        column.align = !column.align ? 'right' : null;
        const numArr = ['min', 'max'];
        $.map(numArr, (v) => {
          if (column.validations && typeof column.validations[v] === 'string') {
            column.validations[v] = Number(column.validations[v]);
          }
        });
      } else if (column.type === 'checkbox' || column.type === 'radio') {
        column.align = !column.align ? 'left' : null;
      } else if (column.type === 'date' || column.type === 'datetime' || column.type === 'calendar') {
        column.align = !column.align ? 'center' : null;
      } else if (column.type === 'dropdown') {
        column.multiple = typeof column.multiple === 'undefined' ? false : column.multiple;
      }
      if (this.sheetConfig.method === 'POST') {
        params.columns.push({
          data: column.name,
          name: '',
          searchable: true,
          orderable: true,
          search: {
            value: '',
            regex: false
          }
        });
      }
    });
    this.sheetConfig.columns.params = params;
  }
}
