import { Component, OnInit, Input } from '@angular/core';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CustomTabService } from '@custom/modules/gen/custom-tab/custom-tab.service';
import { AppConstants } from '@custom/core/app-constants';

@Component({
  selector: 'vs-step-forms',
  templateUrl: './step-forms.component.html',
  styleUrls: [ './step-forms.component.scss' ]
})
export class StepFormsComponent implements OnInit {

  @Input() stepforms: any = {};
  isMobile: boolean;
  activeTab: string;

  constructor(private customTab: CustomTabService) { }

  handleTabChange(event: NgbTabChangeEvent) {
    console.log('tab changed');
    this.customTab.handleTabChange(event);
  }

  ngOnInit() {
    this.isMobile = AppConstants.isMobile;
  }

}
