import { Component, OnInit, Input, OnChanges, OnDestroy, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '@app/core/util.service';
import { AppGlobalService } from '@app/app-global.service';
import { Observable, forkJoin, combineLatest } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from 'angularfire2/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowAllViewersComponent } from './show-all-viewers/show-all-viewers.component';
import { DataService } from '../data.service';
declare const $: any;
declare const _: any;

@Component({
  selector: 'app-vs-viewers',
  templateUrl: './vs-viewers.component.html',
  styleUrls: ['./vs-viewers.component.scss']
})
export class VsViewersComponent implements OnInit, OnChanges, OnDestroy {
  public sheetViewer: any;
  public config: any;
  public local: any;
  public qdata: any;
  public tabID: any;
  public colorIndex: number;

  constructor(
    private util: UtilService,
    private ags: AppGlobalService,
    private ts: TranslateService,
    private http: HttpClient,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private data: DataService) { }

  @Input() viewerconfig: any;

  public getRandomColor() {
    const colors = ['#e9578c', '#b675f3', '#12a5b8', '#51b255', '#dfaa0a',
      '#e36943', '#34A853', '#009688', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
      '#2196f3', '#009688', '#4caf50', '#ffeb3b', '#ff9800', '#ff5722'];
    if (this.colorIndex >= colors.length) this.colorIndex = 0;
    const result = colors[this.colorIndex];
    this.colorIndex++;
    return result;
  }

  public getPixel(url, position) {
    const obs = new Observable(observer => {
      let x; let y;
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.width = 100;
      img.height = 100;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      img.onload = () => {
        const image = event.target as HTMLImageElement;
        context.drawImage(image, 1, 1);
        switch (position) {
          case 'top-left':
            x = 10; y = 10;
            break;
          case 'top-right':
            x = img.width - 10;
            y = 10;
            break;
          case 'bottom-right':
            x = img.width - 10;
            y = img.height - 10;
            break;
          case 'bottom-left':
            x = 10;
            y = img.height - 10;
            break;

          default: break;
        }
        observer.next(context.getImageData(x, y, 1, 1).data);
      };
    });
    return obs;
  }

  public getCharFromName(user) {
    const name = user.n;
    return name[0].toUpperCase();
  }

  public getPixelCord(imageurl, imgObj): Observable<any> {
    const topLeft = this.getPixel(imageurl, 'top-left').subscribe((data) => {
      imgObj.topleft = Array.prototype.slice.call(data);
    });
    const topRight = this.getPixel(imageurl, 'top-right').subscribe((data) => {
      imgObj.topright = Array.prototype.slice.call(data);
    });
    const bottomRight = this.getPixel(imageurl, 'bottom-right').subscribe((data) => {
      imgObj.bottomright = Array.prototype.slice.call(data);
    });
    const bottomLeft = this.getPixel(imageurl, 'bottom-left').subscribe((data) => {
      imgObj.bottomleft = Array.prototype.slice.call(data);
    });
    return forkJoin([topLeft, topRight, bottomRight, bottomLeft]);
  }

  public getColorForUser(imageData) {
    const obs = new Observable(observer => {
      const imageurl = imageData.p;
      if (imageData.c) {
        observer.next(imageData.c);
      } else if (!imageurl) {
        const c = this.getRandomColor();
        imageData.c = c;
        observer.next(c);
      } else {
        const promise = [];
        let imgObj: any;
        this.getPixelCord(imageurl, imgObj).subscribe(() => {
          const imgObjCopy = { ...imgObj };
          let color;
          if (_.isEqual(imgObjCopy.topleft.sort(),
            imgObjCopy.topright.sort(),
            imgObjCopy.bottomleft.sort(),
            imgObjCopy.bottomright.sort())) {
            color = 'rgba(' + imgObj.topleft.join(',') + ')';
          } else {
            color = this.getRandomColor();
          }
          imageData.c = color;
          observer.next(color);
        });
      }
    });
    return obs;
  }

  /**
   * Update input element with the data of current viewer
   */
  public updateStyles() {
    $('td.user-positioned').css({ border: '' }).removeClass('user-positioned');
    $('td[data-editor-title]').attr('data-editor-title', '');
    for (const viewer in this.sheetViewer) {
      if (!this.sheetViewer[viewer].l) continue;
      this.selectViewerLocation(this.sheetViewer[viewer], null);
    }
  }

  public selectViewerLocation(viewer, retry) {
    const split = viewer.l.split('_$_');
    const elem = $('td[row=' + split[0] + '][col=' + split[1] + ']');
    if (elem.length === 0) {
      if (!retry) retry = 0;
      if (retry > 50) return;
      setTimeout(() => {
        this.selectViewerLocation(viewer, ++retry);
      }, 1000);
    }

    elem.addClass('user-positioned').css({ border: '2px solid ' + viewer.c });
    let title = elem.attr('title') || elem.attr('data-editor-title');
    let newtitle = '';
    if (title === undefined || title == null || title === '') {
      newtitle = viewer.n;
    } else {
      title += ', ' + viewer.n;
      newtitle = title;
    }
    newtitle = _.uniq(newtitle.split(', ')).join(', ');
    elem.attr('data-editor-title', newtitle);
  }

  public attachTooltipStyles() {
    $('body').tooltip({
      selector: '[data-toggle="tooltip"]',
      placement: 'bottom'
    });
    $('.viewer-element').on('shown.bs.tooltip', (e) => {
      const color = e.target.style.borderColor;
      $('.tooltip > .tooltip-inner').css({
        'background-color': color
      });
      $('.tooltip > .arrow').addClass('tooltip-arrow').css({
        'border-bottom': '7px solid ' + color
      });
    });
  }

  /**
   * Show all viewers in the tooltip
   */
  public showAllViewers() {
    if (!this.config.showmoreviewers) {
      this.config.showmoreviewers = true;
    } else {
      this.config.showmoreviewers = false;
    }
  }

  public encodeEmail(data) {
    if (!data) return data;
    return data.replace(/[\.]/g, '_$_').replace(/[@]/g, '_$$$$_');
  }

  public decodeEmail(data) {
    if (!data) return data;
    return data.replace(/(_\$\$_)/g, '@').replace(/(_\$_)/g, '\.');
  }

  public getColorEachviewers(viewers, oldViewers): Observable<any> {
    const promises = [];
    // tslint:disable-next-line:forin
    for (const key in viewers) {
      const userkeys = Object.keys(viewers[key]);
      for (let i = 0; i < userkeys.length; i++) {
        if (this.tabID.toString() === userkeys[i] || viewers[key][userkeys[i]] == null) {
          continue;
        }
        const usertab = key + '_~_' + userkeys[i];
        if (!oldViewers[usertab]) {
          this.sheetViewer[usertab] = {
            l: viewers[key][userkeys[i]] ? viewers[key][userkeys[i]] : '',
            n: this.util.getNameFromEmail(this.decodeEmail(key)),
            p: ''
          };
        } else {
          delete oldViewers[usertab];
          if (!this.sheetViewer[usertab]) {
            this.sheetViewer[usertab] = {};
          }
          this.sheetViewer[usertab].l = (viewers[key][userkeys[i]] ? viewers[key][userkeys[i]] : '');
          this.sheetViewer[usertab].n = this.util.getNameFromEmail(this.decodeEmail(key));
          this.sheetViewer[usertab].p = '';
        }
        this.getColorForUser(this.sheetViewer[usertab]).subscribe((data) => { });
        promises.push(this.getColorForUser(this.sheetViewer[usertab]));
      }
    }
    _.map(Object.keys(oldViewers), (el) => {
      if (this.sheetViewer[el]) {
        delete this.sheetViewer[el];
      }
    });
    return combineLatest(promises);
  }
  public updateViewers(viewerResult) {
    if (this.viewerconfig.minviewers) {
      this.config.viewsDisplayNum = this.viewerconfig.minviewers;
    }
    if (!viewerResult) {
      this.createUserToViewersFS();
      return;
    }

    let viewers = {};
    viewers = viewerResult.viewers || {};
    if (!this.sheetViewer) {
      this.sheetViewer = {};
    }
    const oldViewers = { ...this.sheetViewer };
    this.getColorEachviewers(viewers, oldViewers).subscribe((result) => {
      this.updateStyles();
    });
    const oldKeys = Object.keys(oldViewers);
    for (let index = 0; index < oldKeys.length; index++) {
      delete this.sheetViewer[oldKeys[index]];
    }
    this.config.totalViewers = Object.keys(this.sheetViewer).length;
  }

  public createUserToViewersFS() {
    const services = this.viewerconfig.crud;
    const userInfo = this.ags.get('currentUser') || { email: 'jjithin@vanenburgsoftware.com' };
    const document_id = this.viewerconfig.document_id;
    const collectionName = this.viewerconfig.collection;
    const names = this.viewerconfig.name;

    if (!document_id || !collectionName) {
      console.error('Collection Name & Document Id should be configured in queries');
      return;
    }

    const viewer = {};
    const viewerVal = {};
    const currentUserEmail = this.encodeEmail(userInfo.email);
    viewer[currentUserEmail] = viewerVal;
    const dataToSend = {
      entityName: collectionName,
      name: names,
      entityId: document_id,
      viewers: viewer
    };

    this.http.post<any>(services.post, dataToSend).subscribe(data => {
      // console.log('res ', response);
    });
  }

  public createUpdateViewer(cellrc, isRemove, issnapshotUpdate) {
    const services = this.viewerconfig.crud;
    if (!this.qdata || !this.qdata.data || Object.keys(this.qdata.data).length === 0) return;
    const docdata = this.qdata.data;
    let viewers = {};
    if (docdata && docdata.viewers) {
      viewers = docdata.viewers || {};
    }

    const userInfo = this.ags.get('currentUser') || { email: 'jjithin@vanenburgsoftware.com' };
    let dataToSend = {};
    let viewer = {};
    let tabId = this.tabID;
    let senddata = false;
    const currentUserEmail = this.encodeEmail(userInfo.email);
    if (viewers && !viewers.hasOwnProperty(currentUserEmail)) {
      viewer[currentUserEmail] = {};
      viewer[currentUserEmail][tabId] = '';
      senddata = true;
    } else if (viewers && viewers.hasOwnProperty(currentUserEmail)) {
      tabId = this.tabID;
      viewer = {};
      viewer[currentUserEmail] = viewers[currentUserEmail];
      if (cellrc) {
        if (viewer[currentUserEmail][tabId] !== cellrc) {
          viewer[currentUserEmail][tabId] = cellrc;
          senddata = true;
        }
      } else {
        if (!isRemove && !issnapshotUpdate || (!viewer[currentUserEmail] || viewer[currentUserEmail] && viewer[currentUserEmail][tabId])) {
          if (!viewer[currentUserEmail]) {
            viewer[currentUserEmail] = {};
          }
          if ((!issnapshotUpdate && viewer[currentUserEmail][tabId] || !viewer[currentUserEmail].hasOwnProperty(tabId))) {
            viewer[currentUserEmail][tabId] = '';
            senddata = true;
          }
        }
      }
    }

    if (isRemove && viewer && viewer[currentUserEmail]) {
      viewer = {};
      viewer[currentUserEmail] = {};
      viewer[currentUserEmail][tabId] = null;
      senddata = true;
    }

    if (senddata) {
      this.config.isInit = false;
      dataToSend = {
        entityName: docdata.entityName,
        name: docdata.name,
        entityId: docdata.entityId,
        sid: docdata.sid,
        viewers: viewer
      };
      let service = 'put';
      if (isRemove) {
        service = 'delete';
      }
      this.http.put<any>(services[service], [dataToSend]).subscribe(data => {
        // console.log('res ', response);
      });
    }
  }

  public handleCellSelectionChange(cell) {
    if (!this.config)
      return;
    let rc = null;
    const cellEle = $(cell);
    if (cellEle && cellEle.length > 0)
      rc = cellEle.attr('row') + '_$_' + cellEle.attr('col');
    this.createUpdateViewer(rc, null, null);
  }

  public handleUserRemove() {
    if (!(this.local && this.local.unsubscribeviewer)) {
      return;
    }
    for (let index = 0; index < this.local.unsubscribeviewer.length; index++) {
      this.local.unsubscribeviewer[index].unsubscribe();
    }

    this.createUpdateViewer(null, true, null);
  }

  public buildQueries(doc, queries) {
    if (queries) {
      for (let i = 0; i < queries.length; i++) {
        doc = doc.where(queries[i].field, queries[i].operator || '==', queries[i].value);
      }
    }
    return doc;
  }

  /**
   * Intialize loading of viewers based on the reference input from parent controller
   */
  public loadViewers() {
    if (!(this.viewerconfig && this.viewerconfig.document_id)) return;
    const doc = this.firestore.collection(this.viewerconfig.viewer_collection || 'Viewers')
      .doc(this.viewerconfig.name + '_$_' + this.viewerconfig.collection + '_$_' + this.viewerconfig.document_id).valueChanges();
    this.local.unsubscribeviewer.push(doc.subscribe((data) => {
      this.qdata = {
        data
      };
      this.updateViewers(data);
      this.createUpdateViewer(null, false, true);
    }));
  }

  public displayAllViewers() {
    const modalRef = this.modalService.open(ShowAllViewersComponent);
    modalRef.componentInstance.sheetViewer = this.sheetViewer;
  }

  public removeCurrentViewer(event) {
    const message = '';
    if (typeof event === 'undefined') {
      event = window.event;
    }
    if (event) {
      event.returnValue = message;
    }
    return message;
  }

  public setAllViewersPopupHeight() {
    setTimeout(() => {
      const top = $('app-vs-viewers').offset().top;
      $('app-vs-viewers').find('.viewers-all-users').css({
        'max-height': ($(window).height() - top - 200)
      });
    }, 100);
  }
  ngOnChanges() {
    if (this.config) {
      this.handleUserRemove();
      this.loadViewers();
    }
  }
  public cellSelectionChange(event, cell) {
    this.handleCellSelectionChange(cell);
  }

  public bodyClick(e) {
    const trgt = $(e.target);
    if (!trgt.hasClass('allview-button') && !trgt.hasClass('ccaret') && this.config.showmoreviewers) {
      setTimeout(() => {
        this.config.showmoreviewers = false;
      });
    }
    if ($(e.target).parents('vsexcel').length === 0) {
      this.createUpdateViewer(null, false, null);
    }
  }

  ngOnDestroy() {
    this.handleUserRemove();
    this.cellSelectionChange(null, null);
    $('body').off('click', this.bodyClick);
  }

  public setTabId() {
    const tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = new Date().getTime();
    return tabID;
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.handleUserRemove();
    this.cellSelectionChange(null, null);
    $('body').off('click', this.bodyClick);
  }
  ngOnInit() {
    const self = this;
    this.local = {
      unsubscribeviewer: []
    };
    this.sheetViewer = {};
    this.config = {};
    this.config.isInit = true;
    this.config.totalViewers = 0;
    this.config.viewsDisplayNum = 3;
    this.config.showmoreviewers = false;
    this.tabID = this.setTabId();
    this.config.attrname = null;
    this.loadViewers();
    this.colorIndex = 0;
    this.data.currentCellClick.subscribe(cellAttr => {
      this.createUpdateViewer(cellAttr, null, null);
    });
  }
}
