import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class FsBaseService {
  constructor(private http: HttpClient, private firestore: AngularFirestore) { }
  /**
   * Construct the query object for firestore listner
   * @param service - Object contains records limit
   * @param doc - firestore docid for single document search
   * @param queries - fileter query object
   * @param orderby - orderby object
   */
  public buildQueries(doc: any, queries: any, orderby: any, startafter: any, limit: any) {
    if (queries) {
      for (let i = 0; i < queries.length; i++) {
        doc.query = doc.query.where(queries[i].field, queries[i].operator || '==', queries[i].value);
      }
    }
    if (orderby) {
      if (Array.isArray(orderby)) {
        for (let i = 0; i < orderby.length; i++) {
          doc.query = doc.query.orderBy(orderby[i].field, orderby[i].dir);
        }
      } else {
        doc = doc.orderBy(orderby.field, orderby.dir);
      }
    }
    if (limit) {
      doc.query = doc.query.limit(Number(limit));
    }
    if (startafter) {
      doc.query = doc.query.startAfter(startafter);
    }
    return doc;
  }

  /**
   * Create the service and return the observable.
   * @param queryObj - Contains servvice object, docid, filer Array objects, orderby field objects
   */
  public fireService(queryObj: any) {
    let doc: any;
    doc = this.firestore.collection(queryObj.collectionName);
    if (queryObj.docId) {
      doc = doc.doc(queryObj.docId);
    } else {
      doc = this.buildQueries(doc, queryObj.filter, queryObj.orderby, queryObj.startafter, queryObj.limit);
    }
    doc = doc.stateChanges();
    return doc;
  }
}
