import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

@NgModule({
    declarations: [

    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [
        TranslateModule
    ],
    entryComponents: []
})
export class CustomModule { }