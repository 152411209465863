import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vs-collaboration',
  templateUrl: './vs-collaboration.component.html',
  styleUrls: ['./vs-collaboration.component.scss']
})
export class VsCollaborationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
