/** This class is to maintain the api service properties in a single place.
 * example service object
 * "serviceName": {
 *      url: string;
 *      method: string;
 *      showSpinner?: boolean;
 *      showSuccessMsg?: boolean;
 *      showErrorMsg?: boolean;
 *      successMsg?: string;
 *      errorMsg?: string;
 *      headers?: any;
 * }
 */
import { BaseApiConstants } from './core/base-api-constants';

 export class ApiConstants extends BaseApiConstants {
    public static readonly login: any = {
        url: 'appuserprivileges/authenticate',
        method: 'GET',
        showloading: true
    };
    public static readonly fslogin: any = {
        url: 'firebase/usertoken',
        method: 'GET',
        showloading: true
    };
        public static readonly getChangelog: any = {
        url: 'changelogs/datatable/{entityName}/{entityId}/{fieldName}/{fromModifiedDate}',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsSchoolboard: any = {
        url: 'schoolboards/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateSchoolboard: any = {
        url: 'schoolboards',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteSchoolboard: any = {
        url: 'schoolboards/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getSchoolboardAll_SchoolboardList: any = {
        url: 'schoolboards/schoolboardlists/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsSchoolboard: any = {
        url: 'schoolboards/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getSchoolboardBySid: any = {
        url: 'schoolboards/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_Schoolboard: any = {
        url: 'schoolboards/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly createSchoolboard: any = {
        url: 'schoolboards',
        method: 'POST',
        showloading: true
    };
    public static readonly createSector: any = {
        url: 'sectors',
        method: 'POST',
        showloading: true
    };
    public static readonly updateSector: any = {
        url: 'sectors',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteSector: any = {
        url: 'sectors/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getSectorBySid: any = {
        url: 'sectors/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsSector: any = {
        url: 'sectors/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsSector: any = {
        url: 'sectors/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getSectorAll_SectorList: any = {
        url: 'sectors/sectorlists/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getTeamAll_TeamList: any = {
        url: 'teams/teamlists/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly createTeam: any = {
        url: 'teams',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteTeam: any = {
        url: 'teams/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsTeam: any = {
        url: 'teams/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsTeam: any = {
        url: 'teams/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTeamBySid: any = {
        url: 'teams/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateTeam: any = {
        url: 'teams',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteLocation: any = {
        url: 'locations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateLocation: any = {
        url: 'locations',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsLocation: any = {
        url: 'locations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getLocationAll_LocationList: any = {
        url: 'locations/locationlists/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly createLocation: any = {
        url: 'locations',
        method: 'POST',
        showloading: true
    };
    public static readonly getLocationBySid: any = {
        url: 'locations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_Location: any = {
        url: 'locations/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsLocation: any = {
        url: 'locations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateAppUserPrivileges: any = {
        url: 'appuserprivileges',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCurrentUser: any = {
        url: 'appuserprivileges/authenticate',
        method: 'GET',
        showloading: true
    };
    public static readonly getAppUserPrivilegesBySid: any = {
        url: 'appuserprivileges/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getAppUserPrivilegesByEmail: any = {
        url: 'appuserprivileges/email/{email}',
        method: 'GET',
        showloading: true
    };
    public static readonly getAppUserPrivilegesAll_AppUserPrivileges: any = {
        url: 'appuserprivileges/appuserprivileges/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteAppUserPrivileges: any = {
        url: 'appuserprivileges/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateActualUser: any = {
        url: 'appuserprivileges/updateactualuser/{actualuser}',
        method: 'GET',
        showloading: true
    };
    public static readonly createAppUserPrivileges: any = {
        url: 'appuserprivileges',
        method: 'POST',
        showloading: true
    };
 }
