import { VsScannerService } from './vs-scanner.service';
import { Component, OnInit, forwardRef, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-vs-scanner',
  templateUrl: './vs-scanner.component.html',
  styleUrls: [ './vs-scanner.component.scss' ],
  providers: [

    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VsScannerComponent),
      multi: true
    }
  ]
})
export class VsScannerComponent implements OnInit, ControlValueAccessor {

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;


  public barcodeValue;

  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  public scanEnabled = false;

  public allowedFormats = [ BarcodeFormat.QR_CODE,
  BarcodeFormat.EAN_13,
  BarcodeFormat.CODE_128,
  BarcodeFormat.DATA_MATRIX,
  BarcodeFormat.CODABAR,
  BarcodeFormat.CODE_39,
  BarcodeFormat.CODE_93,
  BarcodeFormat.EAN_8,
  BarcodeFormat.ITF,
  BarcodeFormat.MAXICODE,
  BarcodeFormat.PDF_417,
  BarcodeFormat.RSS_14,
  BarcodeFormat.RSS_EXPANDED,
  BarcodeFormat.UPC_A,
  BarcodeFormat.UPC_E,
  BarcodeFormat.UPC_EAN_EXTENSION,
  BarcodeFormat.AZTEC ];

  constructor(private scannerService: VsScannerService) { }

  get model() {
    return this._model;
  }

  writeValue(value: any): void {
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }


  scanErrorHandler(e: Event) {
    console.log('Error ', e);
    this.scanEnabled = false;
  }

  scanFailureHandler(e: Event) {
    console.log('Failure ', e);
    this.scanEnabled = false;
  }

  scanCompleteHandler(e: Event) {
    console.log('Complete ', e);
    this.scanEnabled = false;
  }

  toggleScan() {
    setTimeout(() => {
      this.scanEnabled = !this.scanEnabled;
    }, 10);

  }

  scanSuccessHandler(result) {
    this.barcodeValue = result;
    setTimeout(() => {
      this.scanEnabled = false;
    }, 10);
    this.scannerService.emit(result);
    this.set(result);
  }

  onStarted(started) {
    console.log(started);
  }

  ngOnInit() {
  }

}
