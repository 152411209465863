import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomElementComponent } from './custom-element.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
	declarations: [
	CustomElementComponent,
	
	],
	imports: [
		CommonModule,
		TranslateModule
	],
	exports: [
		CustomElementComponent
	]
})
export class CustomFormModule { }